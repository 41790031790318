<template>
  <page>
    <template slot="headerInfo">
      <el-button
        v-auth="'pre:export'"
        :disabled="!tableData.length"
        @click="exportData"
        type="primary"
      >
        导出订单信息
      </el-button>
    </template>

    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="订单编号">
          <el-input v-model="params.purchaseNo" placeholder="订单编号" />
        </el-form-item>
        <el-form-item label="门店名称" prop="storesName">
          <el-input v-model="params.storesName" placeholder="门店名称" />
        </el-form-item>
        <el-form-item label="预定时间" prop="date">
          <el-date-picker
            v-model="params.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单状态" prop="status">
          <el-select v-model="params.status" clearable>
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        v-loading="loading"
        height="100%"
      >
        <!-- <el-table-column type="selection" width="55" /> -->
        <el-table-column
          show-overflow-tooltip
          min-width="100"
          prop="purchaseNo"
          label="订单编号"
        />
        <el-table-column
          show-overflow-tooltip
          min-width="100"
          prop="storeName"
          label="预定门店"
        />
        <el-table-column
          show-overflow-tooltip
          min-width="130"
          prop="prePayPrice"
          label="预定收款金额(元)"
        />
        <el-table-column
          show-overflow-tooltip
          min-width="100"
          prop="actualPayPrice"
          label="实收金额(元)"
        />

        <el-table-column
          show-overflow-tooltip
          min-width="180"
          prop="preTime"
          label="预定时间范围"
        />

        <el-table-column
          show-overflow-tooltip
          prop="distributionCycleStr"
          label="配送周期"
        />

        <el-table-column show-overflow-tooltip label="配送模式">
          <template slot-scope="{ row }">
            <span>{{ row.orderType == 1 ? '周期配送' : '自定义配送' }}</span>
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          min-width="130"
          label="生效订单数/总笔数"
        >
          <template slot-scope="{ row }">
            <span>{{ (row.geneNum || 0) + '/' + (row.totalNum || 0) }}</span>
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          width="100"
          prop="statusStr"
          label="订单状态"
        />

        <el-table-column
          show-overflow-tooltip
          prop="createTime"
          label="下单时间"
        />

        <el-table-column label="操作" fixed="right">
          <template slot-scope="{ row }">
            <el-button
              v-auth="'prePurchase-detail:detail'"
              @click="detailHandler(row)"
              type="text"
              size="mini"
            >
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <DetailModal
      v-if="detailVisible"
      :visible.sync="detailVisible"
      :currentItem="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import DetailModal from './components/DetailModal.vue'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      isDetail: false,
      visible: false,
      detailVisible: false,
      currentItem: null,
      loading: false,
      tableData: [
        {
          id: '1'
        }
      ],
      statusObj: {
        1: '未开始',
        2: '进行中',
        3: '已完结',
        4: '已终止'
      },
      statusList: [
        {
          label: '未开始',
          value: 1
        },
        {
          label: '进行中',
          value: 2
        },
        {
          label: '已完结',
          value: 3
        },
        {
          label: '已终止',
          value: 4
        }
      ],

      multipleSelection: [],
      params: {
        limit: 20,
        page: 1,
        purchaseNo: '',
        storesName: '',
        status: '',
        date: []
      },
      totalCount: 0
    }
  },
  components: {
    DetailModal
  },
  created() {},
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val.map(_ => ({
        purchaseNo: _.purchaseNo,
        id: _.id
      }))
    },

    async getData(query) {
      try {
        this.loading = true
        let params = { ...this.params }
        if (query) {
          params.limit = 20
          params.page = 1
        }
        for (let i in params) {
          if (params[i] === '') params[i] = undefined
        }
        if (params.date && params.date.length) {
          params.startTime = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD'
          )
          params.endTime = this.$utils.dateFormat(params.date[1], 'YYYY-MM-DD')
        } else {
          params.startTime = undefined
          params.endTime = undefined
        }
        const { date, ...sendData } = params
        const res = await this.$api.preOrderList.getList(sendData)
        this.tableData = ((res.page && res.page.list) || []).map(item => {
          item.statusStr = this.statusObj[+item.status]

          item.distributionCycleStr =
            item.orderType == 1 ? (item.distributionCycle || 0) + '天一次' : '-'

          item.preTime =
            this.$utils.dateFormat(item.startTime, 'YYYY-MM-DD') +
            '~' +
            this.$utils.dateFormat(item.endTime, 'YYYY-MM-DD')

          return item
        })
        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    detailHandler(row) {
      this.currentItem = row
      this.detailVisible = true
    },

    async exportData() {
      try {
        this.loading = true

        const params = { ...this.params }

        if (params.date && params.date.length) {
          params.startTime = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD'
          )
          params.endTime = this.$utils.dateFormat(params.date[1], 'YYYY-MM-DD')
        } else {
          params.startTime = undefined
          params.endTime = undefined
        }

        await this.$api.preOrderList.exportPreOrderList({
          startTime: params.startTime,
          endTime: params.endTime,
          storesName: params.storesName,
          purchaseNo: params.purchaseNo || undefined,
          status: params.status || undefined
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
