<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="详情"
    :visible.sync="show"
    width="1000px"
  >
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="预定详情" name="first">
        <div class="topWrap">
          <!-- <p class="tipsHeader">预定时间内共{{ totalCount }}笔订单</p> -->

          <el-form size="small" label-width="100px">
            <el-form-item label="配送模式：">
              <span>{{
                dataForm.orderType == 1 ? '周期配送' : '自定义配送'
              }}</span>
            </el-form-item>

            <el-form-item label="预定时间：">
              <span>{{ preTime || '-' }}</span>
            </el-form-item>
            <el-form-item v-if="dataForm.orderType == 1" label="配送周期：">
              <span v-if="distributionCycle"
                >{{ distributionCycle }}天一次</span
              >
              <span v-else>-</span>
            </el-form-item>
          </el-form>
        </div>

        <el-divider content-position="left">商品信息</el-divider>

        <el-table
          :data="tableData"
          max-height="300"
          v-loading="loading"
          style="width: 100%"
        >
          <el-table-column prop="goodsName" label="商品名称"></el-table-column>
          <el-table-column prop="barNo" label="条形码"></el-table-column>
          <el-table-column
            prop="purchaseUnit"
            label="采购单位"
          ></el-table-column>
          <el-table-column label="采购单价(元)">
            <template slot-scope="{ row }">
              <span>{{ row.inPrice }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="goodsNum" label="采购数量"> </el-table-column>
          <el-table-column prop="payPrice" label="金额小计(元)">
          </el-table-column>
        </el-table>

        <el-divider content-position="left">收货信息</el-divider>

        <el-form
          style="width: 900px"
          size="small"
          :model="dataForm"
          ref="dataForm"
          label-width="110px"
        >
          <el-form-item label="收货人：" prop="cargoReceiver">
            <span>{{ dataForm.cargoReceiver || '-' }}</span>
          </el-form-item>
          <el-form-item label="联系方式：" prop="phone">
            <span>{{ dataForm.phone || '-' }}</span>
          </el-form-item>
          <el-form-item label="收货详细地址：" prop="storeAddress">
            <span>{{ dataForm.storeAddress || '-' }}</span>
          </el-form-item>
          <el-form-item label="备注：" prop="remark">
            <span>{{ dataForm.remark || '-' }}</span>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="配送计划" name="second">
        <!-- <p class="tipsHeader">预定时间内共{{ totalCount }}笔订单</p> -->
        <el-table :data="tableData2" v-loading="loading" :max-height="400">
          <el-table-column
            show-overflow-tooltip
            prop="sendTime"
            label="预计配送时间"
          />
          <el-table-column show-overflow-tooltip prop="status" label="状态">
            <template slot-scope="{ row }">
              <span>{{
                row.status == 1
                  ? '未生成'
                  : row.status == 2
                  ? '已生成'
                  : '生成失败'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="purchaseId"
            label="关联订单号"
          />
          <el-table-column show-overflow-tooltip prop="remark" label="备注" />
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      activeName: 'first',
      preTime: '',
      distributionCycle: '',
      totalCount: 0,
      btnLoading: false,
      tableData: [],
      tableData2: [],
      rules: {
        cargoReceiver: [{ required: true, message: '请输入收货人' }],
        phone: [{ required: true, message: '请输入联系方式' }],
        storeAddress: [{ required: true, message: '请选择收货地址' }],
        address: [{ required: true, message: '请输入详细地址' }]
      },
      dataForm: {
        cargoReceiver: '',
        phone: '',
        storeAddress: [],
        address: '',
        remark: ''
      },
      loading: false
    }
  },
  created() {
    this.getDetailData()
  },
  methods: {
    handleClick(tab) {
      if (tab == 'first') {
        this.getDetailData()
      } else {
        this.getPlanData()
      }
    },
    async getPlanData() {
      this.loading = true
      try {
        const res = await this.$api.productPurchase.getPreOrderPlan({
          purchaseNo: this.currentItem.purchaseNo
        })

        this.tableData2 = res.detail
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getDetailData() {
      this.loading = true
      try {
        const res = await this.$api.productPurchase.getPreOrderDetail({
          purchaseNo: this.currentItem.purchaseNo
        })

        this.dataForm.storeId = res.detail.storeId
        this.dataForm.cargoReceiver = res.detail.cargoReceiver
        this.dataForm.phone = res.detail.phone
        this.dataForm.orderType = res.detail.orderType

        this.dataForm.storeAddress =
          (res.detail.provinceName || '') +
          (res.detail.cityName || '') +
          (res.detail.regionName || '') +
          (res.detail.address || '')

        this.dataForm.remark = ''

        this.preTime =
          this.$utils.dateFormat(res.detail.startTime, 'YYYY-MM-DD') +
          '~' +
          this.$utils.dateFormat(res.detail.endTime, 'YYYY-MM-DD')

        this.distributionCycle = res.detail.distributionCycle

        this.tableData = res.detail.prePurchaseDetailList
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    saveAddress() {
      this.$refs.dataForm.validate(async valid => {
        if (valid) {
          this.btnLoading = true

          const { storeAddress, ...sendData } = this.dataForm

          if (storeAddress && storeAddress.length) {
            sendData.provinceId = storeAddress[0]
            sendData.cityId = storeAddress[1] || ''
            sendData.regionId = storeAddress[2] || ''
          }

          try {
            await this.$api.productPurchase.updateStoreSoppingCart(sendData)
            this.$message.success('更新成功')
          } catch (e) {
            console.log(e)
          } finally {
            this.btnLoading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.tipsHeader {
  margin: 10px 0;
  color: #999;
  text-indent: 16px;
}

.content {
  margin: 15px 0;
}
.file {
  opacity: 0;
  left: -1000px;
  position: absolute;
}
.tip {
  font-size: 14px;
  letter-spacing: 0px;
  color: #666666;
  margin-bottom: 15px;
}
.file-name {
  margin-left: 12px;
  font-size: 14px;
}

.topWrap {
  ::v-deep .el-form-item--small.el-form-item {
    margin-bottom: 3px;
  }
}
</style>
